import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plate Raise 3×10`}</p>
    <p>{`Fire Hydrants 3×10/leg`}</p>
    <p>{`SDHP’s 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Deadlifts (225/155)(RX+ 275/185)`}</p>
    <p>{`Box Jump Overs (24/20″)`}</p>
    <p>{`HSPU’s (RX+ 2″ deficit)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      